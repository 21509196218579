import React, { useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import Link from '../../components/Link';

const ns = `site-footer`;

const renderCopyRight = () => {
	return `©${ new Date().getFullYear() } Envoy`;
};

const Footer = () => {
	const footerRef = useRef(null);
	const spacerRef = useRef(null);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const handleResize = () => {
		const footerEl = footerRef.current;
		const spacerEl = spacerRef.current;

		if (!spacerEl) return;

		if (window.innerWidth > 992) {
			spacerEl.style.marginBottom = `${ footerEl.offsetHeight }px`;
		} else {
			spacerEl.style.marginBottom = `0px`;
		}
	};

	useEffect(() => {
		// for initial page load
		handleResize();

		window.addEventListener('resize', debounce(handleResize, 150), { passive: true });

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			<div ref={spacerRef} className={`${ ns }__spacer`} />
			<footer ref={footerRef} className={`${ rootClassnames } color-white`}>
				<div className={`container container--offset`}>
					<div className={`${ ns }__main-content`}>
						<ul className={`${ ns }__locations`}>
							<li className={`${ ns }__location`}>
								<h3>OC</h3>
								<address>
									Irvine – CA
									{' '}
									{'\n'}
									34 Tesla Ste 100
									{' '}
									{'\n'}
									Irvine, CA 92618
								</address>
							</li>
							<li className={`${ ns }__location`}>
								<h3>SD</h3>
								<address>
									San Diego – CA
									{' '}
									{'\n'}
									4990 N Harbor Dr Ste 200
									{' '}
									{'\n'}
									San Diego, CA 92106
								</address>
							</li>
							<li className={`${ ns }__location`}>
								<h3>CHI</h3>
								<address>
									Chicago – IL
									{' '}
									{'\n'}
									327 N Aberdeen Ste 201
									{' '}
									{'\n'}
									Chicago, IL 60607
								</address>
							</li>
						</ul>
						<ul className={`${ ns }__contact`}>
							<li className={`${ ns }__contact-item`}>
								<div className={`eyebrow`}>New Business</div>
								<p>
									<a href={`mailto:engage@weareenvoy.com`}>engage@weareenvoy.com</a>
								</p>
								<p>
									<a href={`tel:9494300349`}>949-430-0349</a>
								</p>
							</li>
							<li className={`${ ns }__contact-item`}>
								<div className={'eyebrow'}>General Inquiries</div>
								<p>
									<a href={`mailto:hello@weareenvoy.com`}>hello@weareenvoy.com</a>
								</p>
								<p>
									<a href={`tel:9493870117`}>949-387-0117</a>
								</p>
							</li>
							<li className={`${ ns }__contact-item`}>
								<div className={'eyebrow'}>Careers</div>
								<p>
									<a href={`mailto:careers@weareenvoy.com`}>careers@weareenvoy.com</a>
								</p>
								<p>
									<Link to={`/careers`}>View Openings</Link>
								</p>
							</li>
						</ul>
						<ul className={`${ ns }__secondary-links`}>
							<li>{renderCopyRight()}</li>
							<li>
								<a href={`https://www.instagram.com/weareenvoy/`} rel={`noreferrer`} target={`_blank`}>Instagram</a>
								,&nbsp;
								<a href={`https://www.linkedin.com/company/we-are-envoy`} rel={`noreferrer`} target={`_blank`}>LinkedIn</a>
							</li>
							<li>
								<Link to={`/privacy`}>Privacy</Link>
								,&nbsp;
								<Link to={`/terms`}>Terms</Link>
							</li>
						</ul>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
